import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"

import { Button, Menu, Modal } from "antd"
import sertissage from "./sertissage"

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import montres_savoir_faire_001 from "../images/images/montres_savoir_faire/Reparations .jpg"
import montres_savoir_faire_002 from "../images/images/montres_savoir_faire/entretiennettoyage.jpg"
import montres_savoir_faire_003 from "../images/images/montres_savoir_faire/changementpiles.jpg"
import montres_savoir_faire_004 from "../images/images/montres_savoir_faire/restaurattion.jpg"
import montres_savoir_faire_005 from "../images/images/montres_savoir_faire/changementracelet.jpg"
import montres_savoir_faire_006 from "../images/images/montres_savoir_faire/conciergerie.jpg"
import montres_savoir_faire_007 from "../images/images/montres_savoir_faire/changementbracelets.jpg"


const Bijoux = () => {

//Hooks
  const [dataContact, setDataContact] = useState(false)

//Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
  <Layout>
    <SEO title="Bijoux" />

    <div className="slideshow">
      <div className="usp">
        <div  className="usp-txt-header">Notre savoir-faire</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>


    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Notre savoir-faire</h2>


    <a className="anchor" id="reparation_de_montres"></a>
    <section id="reparation_de_montres">
      <h3>Réparations de montres</h3>
      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{maxWidth: '240px'}} src={montres_savoir_faire_001} alt="Réparations de montres"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font">Nous réparons toutes les montres, peu importe leurs complications, vintages & Modernes.</p>
        </div>

      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Réparations de montres') }>Nous contacter</Button></div>
    </section>

    <div className="separator"></div>
    <a className="anchor" id="entretiens_et_nettoyages"></a>
    <section>
      <h3>Entretiens et Nettoyages</h3>
      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{maxWidth: '240px'}} src={montres_savoir_faire_002} alt="Entretiens et Nettoyages"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font">Entretenir périodiquement votre garde-temps permettra de lui assurer une longue durée de vie.</p>
        </div>

      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Entretiens et Nettoyages') }>Nous contacter</Button></div>
    </section>

    <div className="separator"></div>
    <a className="anchor" id="changements_de_piles"></a>
    <section>
      <h3>Changements de piles</h3>
      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{maxWidth: '240px'}} src={montres_savoir_faire_003} alt="Changements de piles"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font">Changer une pile peut paraître anodin. Un processus strict et un savoir professionnel sont impératifs afin d’éviter tous mauvais fonctionnement. Nous sommes outillés et compétents pour intervenir sur toutes les marques de montres.</p>
        </div>

      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Changements de piles') }>Nous contacter</Button></div>
    </section>

    <div className="separator"></div>
    <a className="anchor" id="restaurations"></a>
    <section>
      <h3>Restaurations</h3>
      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{maxWidth: '240px'}} src={montres_savoir_faire_004} alt="Restaurations"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font">Avec l’usures des années, le manque d’entretien ou les accidents, la détérioration générale d’une montre peut être impressionnante et importante. Suivant sa marque et son époque de fabrication, il est impératif de respecter chaque étapes de sa restauration.</p>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Restaurations') }>Nous contacter</Button></div>
    </section>

    <div className="separator"></div>
    <a className="anchor" id="changements_de_bracelets"></a>
    <section>
      <h3>Changements de bracelets</h3>

      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{maxWidth: '240px'}} src={montres_savoir_faire_005} alt="Changements de bracelets"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font" style={{ paddingBottom: 0}}>Retrouvez un large choix de bracelets dans nos boutiques.</p>
          <p className="p-2 p-font" style={{ padding: 0}}>Cuirs de toutes les couleurs, métal, silicones, caoutchoucs : Toutes marques de montres !</p>
          <p className="p-2 p-font" style={{ padding: 0}}>Etant concessionnaire de la marque « Everest », vous trouverez aussi un large choix pour votre montre Rolex.</p>        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Changements de bracelets') }>Nous contacter</Button></div>
    </section>

    <div className="separator"></div>
    <a className="anchor" id="conciergerie"></a>
    <section>
      <h3>Conciergerie</h3>
      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{maxWidth: '240px'}} src={montres_savoir_faire_006} alt="Conciergerie"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font" style={{ paddingBottom: 0}}>Recherchez-vous une montre particulière ? Nous vous la trouvons !
            Peu importe sa marque et son modèle.
          </p>
          <p className="p-2 p-font" style={{ padding: 0}}>
            Les relations particulières que nous avons entrepris avec les différentes manufactures horlogères, nous permettent d’avoir accès à un large choix de montres et accessoires multi-marques.
          </p>        </div>

      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Conciergerie') }>Nous contacter</Button></div>
    </section>

    <div className="separator"></div>
    <a className="anchor" id="rachat"></a>
    <section>
      <h3>Rachat de montres toutes marques & dépôt-vente</h3>
      <div className="section-flex-01" style={{margin: '0px'}}>

        <img className="img img-ratio" style={{ maxWidth: '240px' }} src={montres_savoir_faire_007} alt="Rachat de montres toutes marques & dépôt-vente"/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className="p-2 p-font" style={{ paddingBottom: 0}}>
            N’hésitez pas à nous contacter pour un devis de reprise sur votre montre. Nous la rachetons au meilleur prix possible.
          </p>
          <p className="p-2 p-font" style={{ padding: 0}}>
            En fonction de son état et de son importance, nous pratiquons aussi le « dépôt-vente ». Gratuitement, nous prenons votre montre en dépôt et lui trouvons un nouveau propriétaire. Le montant convenu ensemble vous sera ensuite réglé.
          </p>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Rachat de montres toutes marques & dépôt-vente') }>Nous contacter</Button></div>
    </section>

    <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
      visible={ showModalContact }
      onOk={ handleContactFormOk }
      onCancel={ handleContactFormCancel }
      okButtonProps={{ style: { display: 'none' } }}
    >

      <ContactForm page={dataContact} />

    </Modal>

  </Layout>
    </>
  )
}

export default Bijoux
